import '../App.css'

function Vragen (){
    return(
        <div>
<div className="tree-node element columns tree-container tree-horizontal">
    <div className="tree-node element column tree-container node-is-first-child lt600 lt800 lt540 lt480 lt300 lt400 width-25">
    <div className="tree-node element image-text node-is-first-child node-is-last-child">
    <div className="element-imagetext-text">
            <h2 className="heading-100 text-align-left">Veel gestelde vragen</h2>    </div>
</div></div><div className="width-9 tree-node element column tree-container lt600 lt800 lt540 lt480 lt200 lt300 lt400">
    <div className="tree-node element spacer node-is-first-child node-is-last-child">
    <div className="element-spacer-container height-50">
    </div>
</div></div><div  className="width-66 tree-node element column tree-container node-is-last-child lt800">
    <div className="tree-node element accordion node-is-first-child node-is-last-child">
    
<div className="element-accordion element-accordion--style-box element-accordion--align-icon-right element-accordion--single-open">
            <details className="element-accordion__item">
            <summary className="
                element-accordion__heading
                element-accordion__heading--icon-triangle            ">
                <i className="element-accordion__icon website-rendering-icon-right-open"></i>
                <h3>Hoe lang duurt een bezoek aan de mondhygienist?</h3>            </summary>
            <div className="element-accordion__content">
                <div className="element-accordion__content-wrap">
                    Een mondhygienist bezoek duurt meestal tussen de 30-60 minuten.                </div>
            </div>
        </details>
            <details className="element-accordion__item">
            <summary className="
                element-accordion__heading
                element-accordion__heading--icon-triangle            ">
                <i className="element-accordion__icon website-rendering-icon-right-open"></i>
                <h3>Hoeveel kost een behandeling?</h3>            </summary>
            <div className="element-accordion__content">
                <div className="element-accordion__content-wrap">
                    De prijs van een behandeling wordt berekend door middel van een 5 minuten tarief.<br></br> a € 15,78. Dit valt onder de M codes
                    <br />
                    <br />
                    Bij een meer uitgebreide behandeling wordt het tarief per tand berekend. dit valt onder de parodontologie codes (T codes)Voor de exacte prijzen kunt u met mij contact opnemen klik dan <a href="#contact">hier</a>
                    </div>
            </div>
        </details>
            <details className="element-accordion__item">
            <summary className="
                element-accordion__heading
                element-accordion__heading--icon-triangle            ">
                <i className="element-accordion__icon website-rendering-icon-right-open"></i>
                <h3>Wat zijn de openingstijden?</h3>            </summary>
            <div className="element-accordion__content">
                <div className="element-accordion__content-wrap">
                    We zijn geopend op maandag, dinsdag, woensdag, donderdag en vrijdag van 8:00 tot 18:00.                </div>
            </div>
        </details>
        <details className="element-accordion__item">
            <summary className="
                element-accordion__heading
                element-accordion__heading--icon-triangle            ">
                <i className="element-accordion__icon website-rendering-icon-right-open"></i>
                <h3>Hoe zeg ik mijn afspraak af</h3>            </summary>
            <div className="element-accordion__content">
                <div className="element-accordion__content-wrap">
                    Hiervoor kunt u contact met mij opnemen, mijn contact gegevens kunt u <a href="#contact">hier</a> vinden.
                <br />
                <br />
                Het is noodzakelijk de afspraak minstens  48 uur van tevoren af te zeggen om kosten berekend voor besproken tijd te vermijden.
<br />
<br />
<strong>LET OP:   afspraken die afgezegd worden na 18.00 uur, en in het weekend, worden de eerstvolgende werkdag vanaf 09:00 pas verwerkt.</strong>
<br />
<br />
Hierdoor kan het voorkomen dat u denkt op tijd te hebben geannuleerd, maar dat wij in de praktijk niet voldoende gelegenheid hebben om de vrijgekomen tijd weer in te vullen.
                </div>
            </div>
        </details>
        </div>
        </div>
        </div>
        
        </div>
        <div  className="tree-node element spacer">
    <div className="element-spacer-container height-50">
    </div>
</div>
        
        </div>
    )
} export default Vragen