import '../App.css'

function Orthomoleculair (){
    return(
<div>
<div id="orthomoleculair" className="tree-node element image-text">
    <div className="element-imagetext-text">
            <h1 className="heading-130 text-align">Orthomoleculair</h1>    </div>
</div>
<div className="element-imagetext-text">
    <p className='text-align'>
    De orthomoleculaire geneeskunde is erop gericht het lichaam te voorzien van alle benodigde bouwstoffen om optimaal te kunnen functioneren. Orthomoleculaire voeding gaat verder dan het elimineren van kant-en-klaar maaltijden, gefrituurde snacks en suiker. Het is een samenspel van de juiste voedingselementen en voedselcombinaties. Door te kiezen voor biologische voeding, seizoensproducten en kwaliteitsvoedsel bereik je een perfect lichamelijk evenwicht en een optimale energiebalans.
    </p>
    <br />
    <p className='text-align'>
    Een Orthomoleculair therapeut voorziet je van de kennis over orthomoleculaire voeding en het gebruik van supplementen. Eugenie besteedt onder andere aandacht aan:
</p>
<ul>
    <li className='list'>
    Orthomoleculaire geneeskunde voor gezondheidsdoeleinden, levensverlenging en cosmetische toepassingen.
    </li>
    <li className='list'>
    Met juiste voeding het lichaam opbouwen, versterken en onderhouden.
    </li>
    <li className='list'>
    De samenstelling, werking en optimale dosering van antioxidanten, aminozuren en andere stoffen.
    </li>
    <li className='list'>
    Hoe kan ik met vitaminen, mineralen en andere supplementen een medische behandeling ondersteunen.
    </li>
    <li className='list'>
    Onderbouwt behandeling van chronische klachten. En gebruikt hiervoor onder andere de Energetisch Morfologisch Bloed test (EMB test )
    </li>
    <li className='list'>
    Ze kijkt naar  de hormoonspiegel, neurotransmitters en de bloedsuikerspiegel.
    </li>
    <li className='list'>
    Het zuur-base-evenwicht, een gezond darmflora en het immuunsysteem.
    </li>
    <li className='list'>
    Ze geeft op verantwoorde wijze orthomoleculaire voedingsadviezen.
    </li>
</ul>
<p>Er zijn meerdere tests beschikbaar in de praktijk, ik werk met de EMB test Energetisch Morfologisch Bloedtest. Enkele druppels bloed worden met een vingerprik  opgevangen in een buisje. Vervolgens wordt het bloed op 180 punten onderzocht, en komt u erachter wat de oorzaak kan zijn van uw klachten. Deze test kost inclusief laboratoriumkosten 250 euro.</p>
<p>Een andere test is de AGE scanner, Advanced Glygaemic Endproducts. Hiermee komt u erachter wat de werkelijke leeftijd van uw lichaam en huid is. De kosten zijn 10 euro per scan.</p>
</div>
    {/* <div className="tree-node element button">
        <div className="btn-container btn-is-center">
                <a href="diensten.html" className="element-content btn btn-sm btn-style-flat btn__default-color btn-rounded">
                            <div className="btn-caption">
                    Meer informatie            </div>
            </a>
        </div>
    </div> */}
</div>
    )
} export default Orthomoleculair