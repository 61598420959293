import '../App.css';
function Welcome (){
    return(
    <div className="tree-node element image-text node-is-first-child">
    <div className="element-imagetext-text">
            <h1 className="heading-130 text-align">Welkom bij mond en lijf gezond</h1>
            <p className='font-size-x text-align'>
                Welkom op de website Mond en Lijf Gezond. Mondhygieniste en Orthomoleculair therapeut Eugenie Rebel is sinds 1995 afgestudeerd aan de ACTA in Amsterdam als mondhygienist en heeft de opleiding Orthomoleculair therapie afgerond in 2019. Zij is werkzaam op deze locatie sinds 2001. De kennis van beide beroepen worden geïmplementeerd in de behandelingen.
            </p>
            <br />
            <p className='font-size-x text-align'>
Als u contact wilt opnemen voor een afspraak klik dan <a href="#contact">hier</a>.
</p>
        </div>
</div>
)
} export default Welcome