import '../App.css'
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import Reaptcha from 'reaptcha';


function Contact (){
    const [captchaToken, setCaptchaToken] = useState(null);
    const [disabled, setDisabled] = useState(true);
    
    const form = useRef();
const captchaRef = useRef(null);
   
   
    

    function setCookie(name, value, minutes) {
        var date = new Date();
        date.setTime(date.getTime() + (minutes * 60 * 1000));
        var expires = "expires=" + date.toUTCString();
        document.cookie = name + "=" + value + ";" + expires + ";path=/";
    }

    function getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    window.onload = function() {
        if (getCookie("buttonDisabled") === "true") {
            document.getElementById("myButton").disabled = true;
        }
    }

    function buttonEnabler(e){
        const tre =e
        if (tre != null){
            setDisabled(false)
        }
     
    }


const verify = () =>{
        captchaRef.current.getResponse().then(res => {
            setCaptchaToken(res)
        buttonEnabler(res)

        })

    }

  const sendEmail = (e) => {
    document.getElementById("myButton").disabled = true;
        setCookie("buttonDisabled", "true", 30);  // Disable for 30 minutes 
    e.preventDefault();
    
    
    emailjs
      .sendForm('service_8zizkwt', 'template_4wgfnq2', form.current, {
        publicKey: 'xv2_eA4zqWpMMhW15',
      })
      .then(
        () => {
            window.alert("Uw bericht is succesvol verstuurd, ik reageer zo snel mogelijk!");
            let input1 = document.getElementById('naam')
            let input2 = document.getElementById('email')
            let input3 = document.getElementById('bericht')
            input1.value = "";
            input2.value = "";
            input3.value = "";
        },
        (error) => {
          window.alert('Uw bericht kon niet verstuurd worden want ', error.text);
        },
      );
  };

    return(
        
        // <!-- start of Neem contact met ons op en map -->
        <div id="contact" className="tree-node element columns tree-container tree-horizontal">
            <div  className="width-34 tree-node element column tree-container node-is-first-child lt600 lt800 lt540 lt480 lt400">
            <div  className="tree-node element image-text node-is-first-child">
            <div className="element-imagetext-text">
                    <h2 className="heading-100">Hoe neemt u contact op?</h2>
                    <p>
                        De praktijk is van maandag tot en met vrijdag geopend en is dan bereikbaar op dit nummer: 035-6282998 tussen 9:00 en 18:00, als er niet wordt opgenomen kunt u altijd een bericht inspreken in het antwoordapperaat.
        
                        <br />
        <br />
                        Daarnaast ben ik bereikbaar via whatsapp via het nummer: 06-43943874
                    
                    <br />
                           <br />     
                        of via een van deze mail adressen:
                        erebel54@gmail.com,
                        <br />
                        mondzorgkundige@gmail.com
        
                    </p>
                    <br />
                    <p>
                        Daarnaast kunt u een mail versturen door dit formulier in te vullen en op de knop versturen te kliken.
                    </p>
                </div>
        </div>
        
        {/* <!-- Neem contact met ons op Form --> */}
        <div id="navigation"></div>
        <div>
        <form ref={form} onSubmit={sendEmail}>                                   
                    <div className="element-form-group">
                        <label className="element-form-label">Naam *
                        </label>
                        <div className="element-form-content">
                            <input id="naam" type="text" required className="form-control element-form-input-text" name="user_name"></input>
                        </div>
                    </div>
                    <div className="element-form-group"><label className="element-form-label">E-mailadres *</label><div className="element-form-content"><input id="email" type="email" required className="form-control element-form-input-text" name="user_email"></input></div></div>
                    <div className="element-form-group"><label className="element-form-label">Bericht *</label><div className="element-form-content"><textarea id="bericht" required className="form-control element-form-input-text" name='message'></textarea></div></div>                                    
                    
                    <div className="element-form-group">
                   
                    <Reaptcha 
       sitekey={process.env.REACT_APP_SITE_KEY}
       ref={captchaRef}
       onVerify={verify} 
      ></Reaptcha>
                        <button id="myButton" disabled={disabled} type="submit" className="element-form-offset btn btn-sm btn-roundness-default btn-style-default btn__default-color" name="submit">
                    <span className="btn-caption">Verzenden</span>
                </button>
                
                    </div>
    </form>
            

        </div>
        </div>
            {/* <!-- makes room --> */}
            <div  className="width-16 tree-node element column tree-container">
            </div>
            <div  className="width-50 tree-node element column tree-container node-is-last-child lt600 lt800">
            
            {/* <!-- map --> */}
        <div  className="tree-node element map node-is-first-child">
            <div className="map-wrapper map--marker-accent">
        
                <div className="mapouter">
                    <div className="gmap_canvas">
                      <iframe title="map" width="100%" height="400" id="gmap_canvas" src="https://maps.google.com/maps?q=1274JD,%20kierkegaardhof%2054&t=&z=17&ie=UTF8&iwloc=&output=embed"
                       frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0">
                      </iframe>
                    </div>
                  </div>
        
            </div>
        </div>
        
        <div className="tree-node element image-text">
            <div className="element-imagetext-text">
            <h3 className="heading-70">Locatie</h3><p>Praktijk voor mondhygiene E. Rebel / Mond en Lijf Gezond<br />Kierkegaardhof 54 1277AL, Huizen, Noord Holland, Nederland</p><p>Ingang aan de zijkant van het pand!</p>
        </div>
        </div>
        
        {/* <!-- social cards --> */}
        <div className="tree-node element social-follow node-is-last-child">
            <div className="element-social-follow element-social-follow--size-m element-social-follow--style-solid element-social-follow--roundness-rounded is-empty element-social-follow--color-br /and text-align-left">
            
                    
            </div>
        </div></div></div>
    )
} export default Contact