import Header from './Header';
import Footer from './Footer';
import Welcome from './compontents/Welcome';
import Spacer from "./compontents/Spacer"
import Diensten from "./compontents/Diensten"
import Orthomoleculair from "./compontents/Orthomoleculair"
import Vragen from "./compontents/Vragen"
import Contact from "./compontents/Contact"
import './App.css';
import React, {  useState } from 'react';

function App() {
  const [theme, setTheme] = useState("light");
  return (
    <div>
      <div className="is-slideshow header-is-text is-segment-page is-frontend is-no-sidebar is-no-messagebar is-no-touch-device is-no-mobile is-website-button is-header-affix menu-is-desktop margin">
    <div className='body'>
      <Header setTheme={setTheme} />
    
    
    <div className={`${theme} clear`}>
    
      <div id="index" className="content-wrap content-width-wrapper">
        <section className="container">
            <div data-section-name="content" className="section section-content">
                <div className="tree-node element simple-root tree-container node-is-first-child node-is-last-child">
      
      <Welcome />
      <Spacer />
      <Diensten />
      <Spacer />
      <Orthomoleculair />
      <Spacer />
      <Vragen />
      <Spacer />
      <Contact />
    </div>
    </div>
    </section>
    
    </div>
    </div>
    <Footer />
    </div>
    </div>
    </div>
    
  );
}

export default App;
