import "../App.css"

function Spacer(){
    return(
    <div>
    <div  className="tree-node element spacer">
    <div className="element-spacer-container height-50">
    </div>
</div><div className="tree-node element separator">
    <div className="element-separator-padding">
    <hr className="element-separator element-separator--thin element-separator--solid" />
</div>
</div>


<div className="tree-node element spacer">
    <div className="element-spacer-container height-50">
    </div>
</div>
</div>
)
} export default Spacer