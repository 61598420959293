import './App.css';
import { ReactComponent as Sun } from "./pictures/Sun.svg";
import { ReactComponent as Moon } from "./pictures/Moon.svg";
import React, { useState,useEffect } from 'react';



function Header (props){
    const [mode, setMode] = useState(true);

  const toggleTheme = () => {
    if (mode === true) {
      props.setTheme('dark');
      setMode(false)
     
    } else {
      props.setTheme('light');
      setMode(true)
      
    }
  };
  
  function useScrollDirection() {
    const [scrollDirection, setScrollDirection] = useState(null);
  
    useEffect(() => {
      let lastScrollY = window.pageYOffset;
  
      const updateScrollDirection = () => {
        const scrollY = window.pageYOffset;
        const direction = scrollY > lastScrollY ? "down" : "up";
        if (direction !== scrollDirection && (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)) {
          setScrollDirection(direction);
        }
        lastScrollY = scrollY > 0 ? scrollY : 0;
      };
      window.addEventListener("scroll", updateScrollDirection); // add event listener
      return () => {
        window.removeEventListener("scroll", updateScrollDirection); // clean up
      }
    }, [scrollDirection]);
  
    return scrollDirection;
  };
  const scrollDirection = useScrollDirection();
    return(
        <div className={`header-scroll ${scrollDirection}`}>
<div className="header-wrap">



<div className="header"> 
<a href="/">
        <h1 className="header-title">
    mond/lijf gezond    </h1>
</a>
</div>

<nav>
<div>
{/* <!-- something weird --> */}

<ul className="menu menu-horizontal">
    <li className="menu-item">
    <input className='dark_mode_input ' type='checkbox' id='darkmode-toggle' onClick={toggleTheme}></input>
<label className='dark_mode_label ' htmlFor='darkmode-toggle'>
    <Moon />
    <Sun />
</label>
    </li>
<li className="menu-item">
    <a href="/" className="menu-link">
    
        Home
    </a>
</li>
<li className="menu-item">
<a href="#diensten" className="menu-link">
    
    Diensten
</a>
</li>
<li className="menu-item">
    <a href="#orthomoleculair" className="menu-link">
        
        orthomoleculair
    </a>
    </li>

<li className="menu-item">
    <a href="#contact" className="menu-link">
    
        Contact
    </a>
</li>
</ul>
</div>
</nav>
</div>
</div>
)
} 
export default Header;
