import './App.css'

function Footer () {
    return(
        <div className="content-width-wrapper">
        <div className="container">
            <div data-section-name="footer" className="section section-footer">
                <div  className="tree-node element simple-root tree-container tree-container__empty">
                </div>
                <div className="credits clear">
                    <div className="credits-owner">
                        <div>
                            <div className="footer-text-content">© 2023 mondhygiene</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
} export default Footer