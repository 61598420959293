import '../App.css'
import Foto1 from "../pictures/Foto 1.jpeg"
import Foto2 from "../pictures/Foto 2.jpeg"
import Foto3 from "../pictures/Foto 3.jpeg"
import Foto4 from "../pictures/Foto 4.jpeg"
import { useState } from 'react'

function Diensten(){
const [page, setPage] = useState(false)

const Diensten2 = () => {
    setPage(true);
  };
const Diensten1 = () => {
    setPage(false);
  };
  
 
  if (page === true){
    return(
        <div id='Diensten' className='wrapper'>
            
            <div className='item'>
            <section>
        <h3 className="heading-text">Mondsituatie</h3>
        <p className="non-heading-text">de mondhygienist screent de mond op afwijkingen en ontstekingen en stelt een passend behandelplan op</p>
    </section>

    {/* <section>
        <h3 className="heading-text">Gebitsafdrukken</h3>
        <p className="non-heading-text">doet bacterieel onderzoek en maakt (in opdracht van de tandarts) röntgenfoto’s</p>
    </section> */}

    <section>
        <h3 className="heading-text">Uitgebreide voorlichting</h3>
        <p className="non-heading-text">Tijdens de behandeling krijgt u uitgebreid voorlichting over het ontstaan van cariës, tandvleesaandoeningen en het effect van voedingsgewoonten, roken en afwijkend mondgedrag (zoals tandenknarsen of duimen) op het gebit. In deze praktijk is er ook veel aandacht voor niet alleen het gebit en de mond maar ook de gezondheid van het hele lichaam komt aan bod.</p>
    </section>

    <section>
        <h3 className="heading-text">Voorlichting en instructies over gebitsverzorging</h3>
        <p className="non-heading-text">De voorlichting wordt aangepast op uw persoonlijke situatie, waaronder het op de juiste manier gebruiken van tandenstokers, ragers en mondspoelmiddelen</p>
    </section>

    <section>
        <h3 className="heading-text">Verzorging van het gebit</h3>
        <p className="non-heading-text">de mondhygienist verwijdert plaque, tandsteen en aanslag en maakt worteloppervlakken glad, zo nodig met behulp van verdoving, ook worden de tanden gepolijst.</p>
    </section>
    <section>
        <h3 className="heading-text">Gebitsbeschermende middelen</h3>
        <p className="non-heading-text">denk hierbij aan fluoridebehandeling door middel van een laklaagje, en chloorhexidine behandelingen. Deze toepassingen worden altijd van tevoren met u besproken.</p>
    </section>
    <section>
        <h3 className="heading-text">Bleken van het gebit</h3>
        <p className="non-heading-text">Thuisbleek methode is mogelijk, bleken van het gebit in de stoel wordt door mijn collega gedaan.  </p>
    </section>
    <div className="button-center">
    <a href onClick={Diensten1} className="element-content btn btn-sm btn-style-flat btn__default-color btn-rounded">
                        <div className="btn-caption">
                Terug            </div>
        </a>
        </div>
        </div>
        <div className='item hiddenImg'>
            <picture className="element-image__image-wrapper image-is-square intrinsic padding-top-120">
                            <img className="element-image__image intrinsic__item" width="339" height="407" alt="" src={Foto4} />
                    </picture>
            </div>
        </div>
    )
  }
  else{
    return(
        <div>
            <div id="diensten" className="tree-node element image-text">
    <div className="element-imagetext-text">
            <h1 className="heading-130 text-align">Diensten</h1>    </div>
</div><div className="tree-node element spacer">
    <div className="element-spacer-container height-50">
    </div>
</div><div className="tree-node element columns tree-container tree-horizontal">
    <div className="tree-node element column tree-container node-is-first-child lt600 lt800 lt540 lt480 lt400 width-33">
    <div className="tree-node element image node-is-first-child">
    <div className="intent element-image element-child element-content element-activeless element-no-margin  element-image-is-center element-image--full-width-to-640">
                        <picture className="element-image__image-wrapper image-is-square intrinsic padding-top-120">
                            <img className="element-image__image intrinsic__item" width="339" height="407" alt="" src={Foto1} />
                    </picture>
        </div>
</div>

<div  className="tree-node element image-text">
    <div className="element-imagetext-text">
            <h3 className="heading-70 text-align" >Advies</h3>    </div>
</div><div  className="tree-node element image-text">
    <div className="element-imagetext-text">
            <p className='text-align'>Voor kinderen tot 18 jaar is mondzorg gratis! Laat uw kind vast wennen aan de mondzorg door vanaf 4 jaar kort te laten controleren. Adviezen worden op maat aangeboden, en gaan over alles in relatie tot het gebit, denk hierbij aan het gebruik van electrische tandenborstel en interdentale reiniging, maar ook (systemische) ziekte, zwangerschap, overgewicht  en de invloeden hiervan op de mond. Met als doel: levenslang een schone en gezonde mond.</p>    </div>
</div><div  className="tree-node element button">
    <div className="btn-container btn-is-center">
            
    </div>
</div><div  className="tree-node element spacer node-is-last-child">
    <div className="element-spacer-container height-50">
    </div>
</div></div>

<div className="tree-node element column tree-container lt600 lt800 lt540 lt480 lt400 width-33 align-items">
    <div className="tree-node element image node-is-first-child">
    <div className="intent element-image element-child element-content element-activeless element-no-margin  element-image-is-center element-image--full-width-to-640">
                        <picture className="element-image__image-wrapper image-is-square intrinsic padding-top-120">
                            <img className="element-image__image intrinsic__item" width="339" height="407" alt="" src={Foto2} />
                    </picture>
        </div>
</div>
<div className="tree-node element image-text">
    <div className="element-imagetext-text">
            <h3 className="heading-70 text-align" >Onderhouden</h3>    </div>
</div><div className="tree-node element image-text">
    <div className="element-imagetext-text">
            <p className='text-align'>Ieder gebit kan te maken krijgen met tandsteen of aanslag. Voorkom kleine irritaties in de mond, Zoals een slechte adem, mondblaasjes maar ook gevoelige tandhalzen en teruggetrokken of bloedend (ontstoken) tandvlees. Dit zijn veelvoorkomende ongemakken. Preventieve zorg is beter dan genezen. Gun uzelf een stralende witte lach. </p>    </div>
</div><div className="tree-node element button">
    <div className="btn-container btn-is-center">
            
    </div>
</div><div className="tree-node element spacer node-is-last-child">
    <div className="element-spacer-container height-50">
    </div>
</div>



</div><div className="tree-node element column tree-container node-is-last-child lt600 lt800 lt540 lt480 lt400 width-33">
    <div  className="tree-node element image node-is-first-child">
    <div className="intent element-image element-child element-content element-activeless element-no-margin  element-image-is-center element-image--full-width-to-640">
                        <picture className="element-image__image-wrapper image-is-square intrinsic padding-top-120">
                            <img className="element-image__image intrinsic__item" width="339" height="407" alt="" src={Foto3} />
                    </picture>
        </div>
</div><div className="tree-node element image-text">
    <div className="element-imagetext-text">
            <h3 className="heading-70 text-align" >Alle diensten</h3>    </div>
</div><div className="tree-node element image-text">
    <div className="element-imagetext-text">
            <p className='text-align'>Om alle diensten en kosten die ik geef te kunnen bekijken klik dan hier op deze knop.</p>    </div>
</div><div className="tree-node element button">
    <div className="btn-container btn-is-center">
            <a href='#Diensten' onClick={Diensten2} className="element-content btn btn-sm btn-style-flat btn__default-color btn-rounded">
                        <div className="btn-caption">
                Meer informatie            </div>
        </a>
    </div>
</div></div></div>
        </div>
    )
  }
} export default Diensten